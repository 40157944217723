import { atom } from "recoil";

export interface IBoundingBox {
  topLeft: number[];
  bottomRight: number[];
}

export interface IAnnotations {
  silhouette: number[][];
  lipsUpperOuter: number[][];
  lipsLowerOuter: number[][];
  lipsUpperInner: number[][];
  lipsLowerInner: number[][];
  rightEyeUpper0: number[][];
  rightEyeLower0: number[][];
  rightEyeUpper1: number[][];
  rightEyeLower1: number[][];
  rightEyeUpper2: number[][];
  rightEyeLower2: number[][];
  rightEyeLower3: number[][];
  rightEyebrowUpper: number[][];
  rightEyebrowLower: number[][];
  rightEyeIris: number[][];
  leftEyeUpper0: number[][];
  leftEyeLower0: number[][];
  leftEyeUpper1: number[][];
  leftEyeLower1: number[][];
  leftEyeUpper2: number[][];
  leftEyeLower2: number[][];
  leftEyeLower3: number[][];
  leftEyebrowUpper: number[][];
  leftEyebrowLower: number[][];
  leftEyeIris: number[][];
  midwayBetweenEyes: number[][];
  noseTip: number[][];
  noseBottom: number[][];
  noseRightCorner: number[][];
  noseLeftCorner: number[][];
  rightCheek: number[][];
  leftCheek: number[][];
}

export interface IFace {
  faceInViewConfidence: number;
  boundingBox: IBoundingBox;
  mesh: number[][];
  scaledMesh: number[][];
  annotations: IAnnotations;
}
export const ShowWatingApprovalState = atom<any>({
  key: "show-wating-approval-state",
  default: false,
});

export const RecipientDataState = atom<any>({
  key:"recipient-data-state-key",
  default:{}
})

export const RecipientRequestLoadingState = atom<boolean>({
  key: "recipient-request-state-key",
  default: false
}) 
