import { atom } from 'recoil';
import { IFacilaProps } from './types';

export type ISignininRoutes =
	| 'phone'
	| 'chooseMethod'
	| 'success-screen'
	| 'otpVerify'
	| 'face-recognize'
	| 'recipient-approval'
	| 'sender-check'
	| 'camera-settings';

export type IJackHenryRoutes =
	| 'chooseMethod'
	| 'success-screen'
	| 'waiting-screen'
	| 'sender-verification-screen'
	| 'jack-recipient-details'
	| '';

export const SignInActiveStepState = atom<ISignininRoutes>({
	key: 'signin-active-step-state',
	default: 'phone',
});

export const jackActiveStepsState = atom<IJackHenryRoutes>({
	key: 'jack-henry-active-step-state',
	default: '',
});

export const FacialPropsState = atom<IFacilaProps>({
	key: 'signin-PropsState',
	default: {
		mobileNumber: '',
		countryCode: '',
		onSuccess: null,
		onFailed: null,
		onBack: null,
		face_recognition: null,
		is_redirect_auth: null,
		is_recipient: false,
		is_message_send: false,
		isrecipient_id: '',
		recipient_status: '',
		fromBank: false,
	},
});
export const jackIsWaitingClosedState = atom({
	key: 'jack-is-closed-waiting',
	default: false,
});

export const FacesState = atom<any>({
	key: 'faces-state',
	default: [],
});

export const FacesPipelineState = atom<any>({
	key: 'faces-pipeline-state',
	default: {},
});

export const AuthenticatedState = atom({
	key: 'faces-state-athenticated',
	default: false,
});

export const runFaceMatcherState = atom({
	key: 'run-face-match-state',
	default: false,
});
