import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { webAuthStatus } from 'states';
import { ShowWatingApprovalState } from 'views/face-auth/stores';

import { CHALLENGE } from './constants';
import { IRegisteredCredentials, IUserInfo } from './type';
import {
	binToStr,
	getRegistrations,
	saveNewCredentials,
	strToBin,
} from './utils';

export const useWebAuthentication = () => {
	const setShowWatingApprovalState = useSetRecoilState(ShowWatingApprovalState);
	const setWebAuthStatus = useSetRecoilState(webAuthStatus);
	const registerNewCredentials = useCallback(
		// eslint-disable-next-line @typescript-eslint/ban-types
		async (callback: Function, userInfo: IUserInfo) => {
			const enc = new TextEncoder();
			const publicKey: PublicKeyCredentialCreationOptions = {
				rp: { name: window.location.host ?? 'exchange.stage.satschel.com' },
				user: {
					name: userInfo.email ?? '',
					id: enc.encode(userInfo.id ?? ''),
					displayName: userInfo.displayName ?? '',
				},
				pubKeyCredParams: [
					{ type: 'public-key', alg: -7 },
					{
						type: 'public-key',
						alg: -257,
					},
				],
				challenge: enc.encode(CHALLENGE),
				authenticatorSelection: { userVerification: 'required' },
			};
			const res = (await navigator.credentials.create({
				publicKey: publicKey,
			})) as any;

			const retResponse = {
				id: res.id,
				authenticatorAttachment: res.authenticatorAttachment,
				rawId: binToStr(res.rawId),
				response: {
					attestationObject: binToStr(res.response.attestationObject),
					clientDataJSON: binToStr(res.response.clientDataJSON),
				},
			};
			if (res?.id) {
				setShowWatingApprovalState(true);
				setWebAuthStatus(true);
			}

			const payload = {
				rawId: binToStr(res?.rawId),
				id: res.id,
				name: userInfo.email ?? '',
				userId: userInfo.id,
				displayName: userInfo.displayName ?? '',
			};

			saveNewCredentials(payload);
			callback(retResponse);
		},
		[]
	);

	const registeredCredentials = () => {
		return getRegistrations().map((reg: IRegisteredCredentials) => ({
			id: strToBin(reg.rawId),
			type: 'public-key',
			transports: ['internal'],
		}));
	};

	const authenticate = useCallback(
		// eslint-disable-next-line @typescript-eslint/ban-types
		async (onSuccess: Function, onFailed: Function) => {
			const enc = new TextEncoder();
			const publicKey = {
				challenge: enc.encode(CHALLENGE),
				allowCredentials: registeredCredentials(),
			};
			// browser receives the publicKey object and passes it to WebAuthn "get" API
			const res = (await navigator.credentials.get({
				publicKey: publicKey,
			})) as any; //as PublicKeyCredential;

			const payload = {
				authenticatorAttachment: 'platform',
				id: res.id,
				rawId: binToStr(res.rawId),
				authenticatorData: binToStr(res.response?.authenticatorData),
				clientDataJSON: binToStr(res.response?.clientDataJSON),
				signature: binToStr(res.response?.signature),
				userHandle: binToStr(res.response?.userHandle),
				type: 'public-key',
			};
			if (res?.id) {
				setShowWatingApprovalState(true);
				setWebAuthStatus(true)
			}

			// here we build an object containing the results, to be sent to the server
			// usually "extractedData" is POSTed to your server
			const extractedData = {
				id: res.id,
				rawId: binToStr(res?.rawId),
				clientDataJSON: binToStr(res.response?.clientDataJSON),
			};

			// Usually done on the server, this is where you make your auth checks
			// here for DEMO purposes only
			const dataFromClient = JSON.parse(atob(extractedData.clientDataJSON));
			const retrievedChallenge = atob(dataFromClient.challenge);

			// At MINIMUM, your auth checks should be:
			// 1. Check that the retrieved challenge matches the auth challenge you sent to the client, as we do trivially below
			// 2. Check that "retrievedOrigin" matches your domain - otherwise this might be a phish - not shown here
			if (retrievedChallenge === CHALLENGE) {
				onSuccess(payload);
				return payload;
			} else {
				onFailed();
				return;
			}
		},
		[]
	);

	return {
		authenticate,
		registerNewCredentials,
		getRegistrations,
	};
};
