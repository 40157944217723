/**
 * @license
 * Copyright 2022 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import * as tf from '@tensorflow/tfjs-core';
// import { FaceRecognition } from 'hooks';

// import { detectBlinkingEyes } from '../utils';

import {
	GREEN,
	NUM_IRIS_KEYPOINTS,
	NUM_KEYPOINTS,
	RED,
	TUNABLE_FLAG_VALUE_RANGE_MAP,
} from './params';
import { TRIANGULATION } from './triangulation';

export function isiOS() {
	return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isAndroid() {
	return /Android/i.test(navigator.userAgent);
}

export function isMobile() {
	return isAndroid() || isiOS();
}

/**
 * Reset the target backend.
 *
 * @param backendName The name of the backend to be reset.
 */
async function resetBackend(backendName: any) {
	const ENGINE = tf.engine();
	if (!(backendName in ENGINE.registryFactory)) {
		throw new Error(`${backendName} backend is not registed.`);
	}

	if (backendName in ENGINE.registry) {
		const backendFactory = tf.findBackendFactory(backendName);
		tf.removeBackend(backendName);
		tf.registerBackend(backendName, backendFactory);
	}

	await tf.setBackend(backendName);
}

/**
 * Set environment flags.
 *
 * This is a wrapper function of `tf.env().setFlags()` to constrain users to
 * only set tunable flags (the keys of `TUNABLE_FLAG_TYPE_MAP`).
 *
 * ```js
 * const flagConfig = {
 *        WEBGL_PACK: false,
 *      };
 * await setEnvFlags(flagConfig);
 *
 * console.log(tf.env().getBool('WEBGL_PACK')); // false
 * console.log(tf.env().getBool('WEBGL_PACK_BINARY_OPERATIONS')); // false
 * ```
 *
 * @param flagConfig An object to store flag-value pairs.
 */
export async function setBackendAndEnvFlags(flagConfig: any, backend: any) {
	if (flagConfig == null) {
		return;
	} else if (typeof flagConfig !== 'object') {
		throw new Error(
			`An object is expected, while a(n) ${typeof flagConfig} is found.`
		);
	}

	// Check the validation of flags and values.
	for (const flag in flagConfig) {
		// TODO: check whether flag can be set as flagConfig[flag].
		if (!(flag in TUNABLE_FLAG_VALUE_RANGE_MAP)) {
			throw new Error(`${flag} is not a tunable or valid environment flag.`);
		}
		if (TUNABLE_FLAG_VALUE_RANGE_MAP[flag].indexOf(flagConfig[flag]) === -1) {
			throw new Error(
				`${flag} value is expected to be in the range [${TUNABLE_FLAG_VALUE_RANGE_MAP[flag]}], while ${flagConfig[flag]}` +
					' is found.'
			);
		}
	}

	tf.env().setFlags(flagConfig);

	const [runtime, $backend] = backend.split('-');

	if (runtime === 'tfjs') {
		await resetBackend($backend);
	}
}

function distance(a: any, b: any) {
	return Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2));
}

function drawPath(ctx: any, points: any, closePath: any) {
	const region = new Path2D();
	region.moveTo(points[0][0], points[0][1]);
	for (let i = 1; i < points.length; i++) {
		const point = points[i];
		region.lineTo(point[0], point[1]);
	}

	if (closePath) {
		region.closePath();
	}
	ctx.stroke(region);
}

/**
 * Draw the keypoints on the video.
 * @param ctx 2D rendering context.
 * @param faces A list of faces to render.
 * @param triangulateMesh Whether or not to display the triangle mesh.
 * @param boundingBox Whether or not to display the bounding box.
 */
export function drawResults(ctx: any, faces: any, triangulateMesh: any) {
	faces.forEach((face: any) => {
		const keypoints = face.keypoints.map((keypoint: any) => [
			keypoint.x,
			keypoint.y,
		]);

		if (triangulateMesh) {
			ctx.strokeStyle = GREEN;
			ctx.lineWidth = 0.5;

			for (let i = 0; i < TRIANGULATION.length / 3; i++) {
				const points = [
					TRIANGULATION[i * 3],
					TRIANGULATION[i * 3 + 1],
					TRIANGULATION[i * 3 + 2],
				].map((index) => keypoints[index ?? 0]);

				drawPath(ctx, points, true);
			}
		} else {
			ctx.fillStyle = GREEN;

			for (let i = 0; i < NUM_KEYPOINTS; i++) {
				const x = keypoints[i][0];
				const y = keypoints[i][1];

				ctx.beginPath();
				ctx.arc(x, y, 1 /* radius */, 0, 2 * Math.PI);
				ctx.fill();
			}
		}

		if (keypoints.length > NUM_KEYPOINTS) {
			ctx.strokeStyle = RED;
			ctx.lineWidth = 1;

			const leftCenter = keypoints[NUM_KEYPOINTS];
			const leftDiameterY = distance(
				keypoints[NUM_KEYPOINTS + 4],
				keypoints[NUM_KEYPOINTS + 2]
			);
			const leftDiameterX = distance(
				keypoints[NUM_KEYPOINTS + 3],
				keypoints[NUM_KEYPOINTS + 1]
			);

			ctx.beginPath();
			ctx.ellipse(
				leftCenter[0],
				leftCenter[1],
				leftDiameterX / 2,
				leftDiameterY / 2,
				0,
				0,
				2 * Math.PI
			);
			ctx.stroke();

			if (keypoints.length > NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS) {
				const rightCenter = keypoints[NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS];
				const rightDiameterY = distance(
					keypoints[NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS + 2],
					keypoints[NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS + 4]
				);
				const rightDiameterX = distance(
					keypoints[NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS + 3],
					keypoints[NUM_KEYPOINTS + NUM_IRIS_KEYPOINTS + 1]
				);

				ctx.beginPath();
				ctx.ellipse(
					rightCenter[0],
					rightCenter[1],
					rightDiameterX / 2,
					rightDiameterY / 2,
					0,
					0,
					2 * Math.PI
				);
				ctx.stroke();
			}
		}

		// TODO: (face, ctx, videoWidth, person name);
	});
}
