{
	/*eslint-disable*/
}
import { useState } from 'react';
import './loaderSegment.scss';
import { FaceRecognition } from 'hooks';

const LoaderSagment = () => {
	const pinkEyeBlink = 'rgba(255, 0, 255, 1)';
	const yellowLiveness = 'rgba(255, 252, 127, 1)';
	const [color, setColor] = useState(pinkEyeBlink);

	const rgbValues = [];

	FaceRecognition.emitter.on('eyeBlink', (color: string) => {
		switch (color) {
			case 'pinkEye_Far':
			case 'pinkEye_Close':
			case 'pinkEyeBlink':
				setColor(pinkEyeBlink);
				break;
			case 'yellowLiveness':
				setColor(yellowLiveness);
				break;
			default:
				setColor(pinkEyeBlink);
				break;
		}
	});
	const colorValues: any = color.match(/\d+/g);

	if (colorValues !== null && colorValues.length === 4) {
		const red = parseInt(colorValues[0]);
		const green = parseInt(colorValues[1]);
		const blue = parseInt(colorValues[2]);
		const alpha = parseFloat(colorValues[3]);

		for (let i = 0; i < 65; i++) {
			const newRed = Math.round(red - i >= 0 ? red - i : 0);
			const newGreen = Math.round(green - i >= 0 ? green - i : 0);
			const newBlue = Math.round(blue - i >= 0 ? blue - i : 0);

			const newColor = `rgba(${newRed}, ${newGreen}, ${newBlue}, ${alpha})`;
			rgbValues.push(newColor);
		}
	}
	// const [size, setSize] = useState(390);
	// useEffect(() => {
	// 	if (window.innerWidth <= 760) {
	// 		setSize(340);
	// 	}
	// }, []);

	return (
		<div className="Profile">
			{/* <div className="Profile-border">
				{rgbValues.map((item: string) => (
					<div className="Profile-border-segmentContainer">
						<svg className="Profile-border-segment" width={size} height={size}>
							<circle
								cx={size / 2}
								cy={size / 2}
								r={size / 2 - 10}
								fill="transparent"
								stroke={item}
								stroke-dasharray="18, 5000"
								stroke-width="8"
								stroke-linecap="round"
								transform={`rotate(-5,${size / 2},${size / 2})`}
							></circle>
						</svg>
					</div>
				))}

				<div className="Profile-borderMask">
					<svg className="Profile-borderMask-gfx" width={size} height={size}>
						<circle
							cx={size / 2}
							cy={size / 2}
							r={size / 2 - 6 + 0.2}
							fill="transparent"
							stroke="#fafafa"
							stroke-width="2"
						></circle>
						<circle
							cx={size / 2}
							cy={size / 2}
							r={size / 2 - 15 + 0.4}
							fill="transparent"
							stroke="#fafafa"
							stroke-width="2"
						></circle>
					</svg>
				</div>
			</div> */}

			<div className="Profile-border-wrapper">
				<div
					className="border-circle border-circle1"
					style={{ backgroundColor: color }}
				></div>
				<div
					className="border-circle border-circle2"
					style={{ backgroundColor: color }}
				></div>
				<div
					className="border-circle border-circle3"
					style={{ backgroundColor: color }}
				></div>
			</div>

			<div className="Profile-imageMask">
				<canvas
					style={{
						borderRadius: '18%',
						width: '96%',
						height: '96%',
					}}
					id="output"
				></canvas>
			</div>
		</div>
	);
};

export default LoaderSagment;
