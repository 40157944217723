import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { jackActiveStepsState } from 'states';
import { ChooseMethod } from 'views/signIn/components/choose-method';
import { WaitingScreen } from './wating-screen';
import { SenderVerificationProcess } from './senderVerifcations';
import { JackRecipientDetailsScreen } from './jack-recipient-details';

export const VerificationAuth = ({ onSuccess }: any) => {
	const jackActiveSteps = useRecoilValue(jackActiveStepsState);
	// const [jackIswaitngClosed, setjackIsWaitingClosed] = useRecoilState(
	// 	jackIsWaitingClosedState
	// );
	const getComponent = useMemo(() => {
			switch (jackActiveSteps) {
				case 'chooseMethod':
					return <ChooseMethod fromBank={true} fromJack={true} />;
				case 'waiting-screen':
					return <WaitingScreen />;
				case 'sender-verification-screen':
					return <SenderVerificationProcess onSuccessCall={onSuccess} />;
				case 'jack-recipient-details':
					return <JackRecipientDetailsScreen />;
				default:
					return <></>;
			}
		
	}, [jackActiveSteps]);

	// const handleCloseModal = useCallback(() => {
	// 	setjackIsWaitingClosed(true);
	// }, []);

	// const renderCloseBtn = useMemo(() => {
	// 	if (jackActiveSteps === 'waiting-screen') {
	// 		return (
	// 			<div onClick={handleCloseModal}>
	// 				<i className="ri-close-line waiting-screen-close" />
	// 			</div>
	// 		);
	// 	} else return <></>;
	// }, [jackActiveSteps]);

	// eslint-disable-next-line react/jsx-one-expression-per-line
	return (
		<div className="verification-auth__container">
			{/* {renderCloseBtn} */}
			{getComponent}
		</div>
	);
};
