/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-console */
import { Button, Loader } from '@storybook';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	recipientIdState,
	recipitentDetailsState,
	SenderStatusLoader,
} from 'states';
import { getDate, getTime } from 'utils/get-date';
// import succussGif from '../asests/success.gif';
import userAvatarImg from '../asests/user_avatar.png';
import loadingGif from '../asests/loading.gif';

import './wating-approval.css';
import { GetQueryParams } from 'utils';
import { FaceDetection, FaceRecognition, useNetwork } from 'hooks';

export const RecipitentDetails = ({
	width,
	handleNextPay,
	isRecipient = false,
}: any) => {
	const { post, isLoaded } = useNetwork();
	const recipitentDetails = useRecoilValue(recipitentDetailsState);
	const [statusLoader, setStatusLoader] = useState(true);
	const [location, setLocation] = useState('Location...');
	const recipientStatus = GetQueryParams('status');
	const setRecipientUserId = useSetRecoilState(recipientIdState);
	// const [ laoder, setLoader]= useState
	const setSenderStatus = useSetRecoilState(SenderStatusLoader);
	console.log('isLoaded', isLoaded);
	const [faceLoader, setFaceLaoder] = useState(true);
	const [loading, setLoading] = useState(true);

	const {
		recipientImage,
		name,
		createdAt,
		status,
		email,
		recipientLocation,
		senderStatus,
		senderPhone,
		_id,
		senderCountryCode,
	} = recipitentDetails ?? {};

	useEffect(() => {
		const ClearFaceDetetion = setInterval(() => {
			if (!!FaceDetection.detector) {
				setFaceLaoder(false);
				console.log(FaceDetection.detector);
				clearInterval(ClearFaceDetetion);
			}
		}, 400);
	}, []);

	useEffect(() => {
		if (status) {
			setRecipientUserId(_id);

			post('/biometrics/get?type=arc-facial', {
				phone: senderPhone,
				countryCode: senderCountryCode,
			})
				.then((data: any) => {
					if (data) {
						setSenderStatus(true);
						FaceRecognition.setupModal(data);
						setStatusLoader(false);
					}
				})
				.catch((err: any) => {
					// eslint-disable-next-line no-console
					console.log('Error', err);
				});
		}
	}, [status, _id, senderPhone, senderCountryCode]);
	// const renderStatus = useMemo(() => {
	//  return (
	//      <>
	//          {status === 'complete' ? (
	//              <img className="succuss-gif" src={succussGif} alt="succuss gif" />
	//          ) : (
	//              <i className="ri-close-circle-fill cancel-icon"></i>
	//          )}
	//          <span className="details-label">
	//              {status === 'complete' ? 'Recipient approved' : 'Recipient rejected'}
	//          </span>
	//      </>
	//  );
	// }, [status]);

	useEffect(() => {
		if (recipientLocation?.latitude && recipientLocation?.longitude) {
			const locationInterval = setInterval(() => {
				fetch(
					'https://nominatim.openstreetmap.org/reverse?format=json&lat=' +
						recipientLocation?.latitude +
						'&lon=' +
						recipientLocation?.longitude
				)
					.then((response) => response.json())
					.then((data) => {
						if (data) {
							console.log('data.display_name', data);
							clearInterval(locationInterval);
							setLocation(data.display_name);
						}
					})
					.catch(() => {
						// handle error
						setLocation('--');
					});
			}, 1000);
		}
	}, [recipitentDetails]);

	const handleImageLoad = () => {
		setLoading(false);
	};

	if (statusLoader) {
		return (
			<div className="spinner-box">
				<img src={loadingGif} alt="loading" />
			</div>
		);
	}

	return (
		<div>
			<div
				style={{ width: width, height: width }}
				className={`waiting-wrapper-recipient ${
					isRecipient ? 'wrapper-recipient' : ''
				}`}
			>
				<div className="recipient-detail-wrapper">
					{loading && (
						<div className="recipitent-img">
							<Loader className="loader-white" dimension={58} />
						</div>
					)}
					<img
						className="recipitent-img"
						src={recipientImage || userAvatarImg}
						onLoad={handleImageLoad}
						alt="img"
						style={{ display: loading ? 'none' : 'block' }}
					/>
					<div className="recipitent-name">{name ?? ''}</div>
					<div className="details-box">
						<div className="recipitent-details-row">
							<span className="recipitent-details-label-type">Email:</span>
							<span className="row-details">{email ?? '--'}</span>
						</div>
						<div className="recipitent-details-row">
							<span className="recipitent-details-label-type">Date :</span>
							<span className="row-details">
								{getDate(createdAt ?? '', 'dd MMM, yyyy') ?? '--'}
							</span>
						</div>
						<div className="recipitent-details-row">
							<span className="recipitent-details-label-type">Time :</span>
							<span className="row-details">
								{getTime(createdAt ?? '') ?? '--'}
							</span>
						</div>
						<div className="recipitent-details-row">
							<span className="recipitent-details-label-type">Location :</span>
							<div data-tooltip={location}>
								<span
									className={`row-details ${
										location ? 'row-details-location' : ''
									} `}
								>
									{location ?? '--'}
								</span>
							</div>
						</div>
						<div className="recipient-next-btn">
							{recipientStatus ? (
								<>
									<p>
										{status === 'rejected'
											? 'Note: Recipient cancelled the request '
											: senderStatus !== 'pending'
											? 'Note: You have already completed the verification'
											: ''}
									</p>
									<Button
										type="button button__filled--primary button__large button__block "
										label={
											faceLoader ? (
												<Loader className="loader-white" dimension={14} />
											) : (
												'Next'
											)
										}
										handleClick={() => handleNextPay()}
										disabled={
											faceLoader ||
											senderStatus === 'completed' ||
											status === 'rejected'
												? true
												: false
										}
									/>
								</>
							) : (
								<Button
									type="button button__filled--primary button__large button__block "
									label={
										isLoaded ? (
											'Next'
										) : (
											<Loader className="loader-white" dimension={14} />
										)
									}
									handleClick={() => handleNextPay()}
									disabled={status === 'rejected' ? true : false}
								/>
							)}
							{/* {status === "rejected" &&  (
							<Button
								type="button button__filled--primary button__large button__block "
								label="Next"
								handleClick={() => handleNextPay()}
								disabled={status === "rejected" ? true : false}
							/>
							) }
							{/* <Button
								type="button button__filled--primary button__large button__block "
								label="Next"
								handleClick={() => handleNextPay()}
								disabled={status === 'rejected' ? true : false}
							/> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
