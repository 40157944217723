import { FaceDetection } from './face-detection';

export const stageToken =
	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYzNzQ5YWEzZWY5ODI4NWI0NWVmNThjYiIsImNsaWVudElkIjoiNDQ4NDA5ZWViYzk4NDc0NzgwZjFmYWZlMjE0NGVmZWQiLCJidXNpbmVzcyI6IjYzNjg5N2JhMTdiMzFkMGFkODk1ODllNSIsImlhdCI6MTY2ODU4NjIxOCwiZXhwIjoxNzAwMTQzODE4fQ.hG4THkYfK8sMVunI-lSr7nY3tywsgz4x7LIhSaZfAz4';

// PROD
export const prodToken =
	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYzOThhY2MzNGRjYzE3Y2NhNzRmNjU5OCIsImNsaWVudElkIjoiMjZlZjhlMmJmNTExNGM1NTgwYjJjYjAzN2I5NjEyODEiLCJidXNpbmVzcyI6IjYzMTIwNDdlMTI3OTQ0YWU4ZTE0N2JmYiIsImlhdCI6MTY3MDk1MDE5NywiZXhwIjoxNzAyNDg2MTk3fQ.MLRJJA2iNsOODN5pg9tJzVCbYzmS83r_zLsY3V0iw8M';

const isDev = () => {
	const defaultValue = false;

	let IS_DEV = defaultValue;
	try {
		IS_DEV = localStorage.get('IS_DEV') ? false : true;
	} catch (error) {
		IS_DEV = defaultValue;
	}
	return IS_DEV;
};

export const token = isDev() ? stageToken : prodToken;

export const useFace = () => {
	const setupCanvas = (size: any) => {
		FaceDetection.camera?.setupCanvas(size);
	};
	return { setupCanvas };
};
