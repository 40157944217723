import './wating-approval.css';

import { useEffect } from 'react';
import { RecipitentDetails } from './recipitent-details';
import { useRecoilValue } from 'recoil';
import { recipitentRejectedState } from 'states';

export const WaitingApproval = ({
	recipientData,
	width,
	handleNextPay,
	setTitle,
}: any) => {
	const rejectedState = useRecoilValue(recipitentRejectedState);
	const IMAGE_GCP_URL = 'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media'
	useEffect(() => {
			setTitle("Waiting for approval")
			if(rejectedState){
				setTitle("")
			}
		
	}, [rejectedState]);
	

	return (
		<div
			className="waiting-wrapper"
			style={{
				width: width,
				height: '85%',
			}}
		>
			<div className="waiting-box">
				{!rejectedState && recipientData?.status !== 'complete' && (
					<>
						{/* <div className="ban-poc-loader"></div> */}
						<img className='waiting-loader' src={`${IMAGE_GCP_URL}/simpliciGif.gif`} alt='loder' />
						<p>Waiting for recipient approval</p>
					</>
				)}

				{rejectedState && (
					<RecipitentDetails isRecipient={false}  width={width} handleNextPay={handleNextPay} />
				)}
			</div>
		</div>
	);
};
