/* eslint-disable no-console */
/* eslint-disable no-var */
import { BoundingBox } from '@tensorflow-models/face-landmarks-detection/dist/shared/calculators/interfaces/shape_interfaces';
import { VIDEO_SIZE } from './shared/params';
import { drawResults, isMobile } from './shared/util';

// const worker = new Worker('getImageCanvas.js');
export class Camera {
	canvas?: HTMLCanvasElement;
	video: HTMLVideoElement;
	ctx?: CanvasRenderingContext2D | null;
	targetImage: string | undefined;
	constructor() {
		this.video = document.getElementById('video') as HTMLVideoElement;
	}

	/**
	 * Initiate a Camera instance and wait for the camera stream to be ready.
	 * @param cameraParam From app `STATE.camera`.
	 */
	async setupCamera(cameraParam: any) {
		if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
			throw new Error(
				'Browser API navigator.mediaDevices.getUserMedia not available'
			);
		}

		const { targetFPS, sizeOption } = cameraParam;
		const $size = VIDEO_SIZE[sizeOption];
		const videoConfig = {
			audio: false,
			video: {
				facingMode: 'user',
				// Only setting the video to a specified size for large screen, on
				// mobile devices accept the default size.
				width: isMobile() ? VIDEO_SIZE['360 X 270'].width : $size.width,
				height: isMobile() ? VIDEO_SIZE['360 X 270'].height : $size.height,
				frameRate: {
					ideal: targetFPS,
				},
			},
		};

		const stream = await navigator.mediaDevices.getUserMedia(videoConfig);

		if (stream) this.video.srcObject = stream;

		await new Promise((resolve) => {
			this.video.onloadedmetadata = () => {
				resolve(this.video);
			};
		});

		this.video.play();
	}

	setupCanvas(size: any) {
		this.canvas = (document.getElementById('output') ??
			document.getElementById('initial-canvas-output')) as HTMLCanvasElement;
		this.ctx = this?.canvas?.getContext('2d');
		const videoWidth = size;
		const videoHeight = size;
		// Must set below two lines, otherwise video element doesn't show.
		this.video.width = videoWidth;
		this.video.height = videoHeight;

		this.canvas.width = videoWidth;
		this.canvas.height = videoHeight;
		const canvasContainer: any = document.querySelector('.canvas-wrapper');
		let containerStyle= `width: ${videoWidth}px; height: ${videoHeight}px`
		if(canvasContainer.style.display === 'none'){
			containerStyle+= `; display: none ` 
		}
		canvasContainer.style = containerStyle


		// canvasContainer.style = `width: ${videoWidth}px; height: ${videoHeight}px`;
		const offsetX = (this.video.videoWidth - videoWidth) / 2;
		this.ctx?.translate(videoWidth, 0);
		this.ctx?.scale(-1, 1);
		this.ctx?.translate(-offsetX, 0);
		// this.ctx?.translate(this.video.videoWidth, 0);
		// this.ctx?.scale(-1, 1);
		return this;
	}

	drawCtx() {
		this.ctx?.drawImage(
			this.video,
			0,
			0,
			this.video.videoWidth,
			this.video.videoHeight
		);
	}

	getImage(box: BoundingBox) {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		canvas.width = this.video.width;
		canvas.height = this.video.height;
		ctx?.drawImage(
			this.video,
			box.xMin,
			box.yMin,
			box.width,
			box.height,
			0,
			0,
			canvas.width,
			canvas.height
		);
		// worker.postMessage(
		// 	{
		// 		imageData: ctx?.getImageData(
		// 			10,
		// 			10,
		// 			this.video.videoWidth,
		// 			this.video.videoHeight
		// 		),
		// 		width: this.video.videoWidth,
		// 		height: this.video.videoHeight,
		// 	},
		// 	[]
		// );
		// worker.onmessage = (workerData) => {
		// 	this.targetImage = workerData.data.image;
		// };

		//return this.targetImage;
		var quality = 0.5;
		var dataUri = canvas.toDataURL('image/jpeg', quality);
		return dataUri;
	}

	drawMask(faces: any, triangulateMesh: any) {
		if (this.ctx) {
			drawResults(
				this.ctx,
				faces,
				triangulateMesh
				// this.video.videoWidth
			);
		}
	}
}
