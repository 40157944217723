import { useEffect } from 'react';
import './wating-approval.css';

export const SuccussApproval = ({ width, setTitle }: any) => {
	useEffect(() => {
		if (window.innerWidth <= 760) {
			setTitle('');
		}
	}, []);
	return (
		<div
			style={{
				display: 'grid',
				placeItems: 'center',
				width: width,
				height: '85%',
				margin: "auto"
			}}
		>
			<div className="waiting-box">
					<div>
						<i className="ri-checkbox-circle-fill icon"></i>
					</div>
					Thanks for the approval
			</div>
		</div>
	);
};
