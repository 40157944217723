import { useCallback, useMemo, useState } from 'react';

import {
	REACT_APP_API_HOST as API_HOST,
	REACT_APP_TOKEN as accessToken,
} from 'envs';

interface IConfig {
	headers?: {
		Authorization?: string;
		'Content-Type'?: string;
	};
}

export const isDev = () => {
	const defaultValue = false;

	let IS_DEV = defaultValue;
	try {
		IS_DEV = localStorage.get('IS_DEV') ? false : true;
	} catch (error) {
		IS_DEV = defaultValue;
	}
	return IS_DEV;
};

export const Host_URL = 'https://biometrics.stage.satschel.com';

export const useNetwork = () => {
	// const { accessToken: token } = useRecoilValue(loginState);

	const [data, setData] = useState<any>(null);
	const [error, setError] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [status, setStatus] = useState<any>(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const config: IConfig = useMemo(() => ({}), []);
	const postConfig: IConfig = useMemo(() => ({}), []);

	if (accessToken) {
		config.headers = {
			Authorization: `Bearer ${accessToken}`,
		};
	}
	postConfig.headers = {
		'Content-Type': 'application/json',
		...(config.headers ?? {}),
	};

	const get = useCallback(
		async (url: string): Promise<any> => {
			setLoading(true);
			try {
				const response = await fetch(API_HOST + url, config);
				const apiPayload = await response.json();
				setStatus(response?.ok);
				setIsLoaded(true);
				setData(apiPayload);
				return apiPayload;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},
		[config, accessToken]
	);

	const post = useCallback(
		async (url: string, requestJSON: any, HostURL = API_HOST) => {
			setLoading(true);
			try {
				const response = await fetch(HostURL + url, {
					method: 'POST',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				if (response.status === 500) {
					setError(response.type);
				}
				setStatus(response?.ok);
				const apiData = await response.json();
				if (url.includes('charts?businessId')) {
					setIsLoaded(true);
					setData(apiData);
					return apiData;
				}
				const apiResponse = apiData.data ?? apiData;
				setIsLoaded(true);
				setData(apiResponse);
				return apiResponse;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},
		[postConfig, accessToken]
	);

	const formData = useCallback(
		async (url: string, requestJSON: any) => {
			setLoading(true);
			try {
				const response = await fetch(API_HOST + url, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
					body: requestJSON,
				});
				const apiData = await response.json();
				setStatus(response?.ok);
				setIsLoaded(true);
				setData(apiData);
				setLoading(false);
				return apiData;
			} catch (err) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},
		[accessToken]
	);

	const put = useCallback(
		async (url: string, requestJSON?: any) => {
			try {
				const response = await fetch(API_HOST + url, {
					method: 'PUT',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				setStatus(response?.ok);
				const apiData = await response.json();
				setStatus(response.status);
				setIsLoaded(true);
				setData(apiData.data);
				return apiData.data;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},
		[postConfig, accessToken]
	);

	const remove = useCallback(
		async (url: string, requestJSON?: any) => {
			try {
				const response = await fetch(API_HOST + url, {
					method: 'DELETE',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				setStatus(response?.ok);
				const apiData = await response.json();
				setStatus(response.status);
				setIsLoaded(true);
				setData(apiData.data);
				return apiData.data;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},
		[postConfig, accessToken]
	);

	const patch = useCallback(
		async (url: string, requestJSON?: any) => {
			setLoading(true);
			try {
				const response = await fetch(API_HOST + url, {
					method: 'PATCH',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				setStatus(response?.ok);
				const apiData = await response.json();
				setIsLoaded(true);
				const apiResponse = apiData.data ?? apiData;
				setData(apiResponse);
				return apiData.data;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},
		[postConfig, accessToken]
	);

	return {
		get,
		post,
		formData,
		put,
		data,
		status,
		error,
		loading,
		setLoading,
		remove,
		patch,
		isLoaded,
		setIsLoaded,
	};
};
