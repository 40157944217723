export const API_URL = {
	CLIENT_LOGIN: 'client-login',
	CLIENT_SIGNUP: 'client-users',
	LOGOUT: '/member-logout',
	FUND_DETAIL: '/fund-details',
	WebAuthLogin: '/client-web-auth-login',
	PhoneCodes: '/client-phone-codes',
	WaitingLogin: '/events',
	LoginStatus: '/status',
	VerifyPhones: '/client-verify-phones',
	REQUEST_RECIPIENT: '/pipelines/request-recipient',
};

export const GOOGLE_API = {
	MAP: 'https://www.google.com/maps/search/?api=1&query=',
};

export const GOOGLE_API_BIOMETRIC = {
	IP: 'https://us-central1-glossy-fastness-305315.cloudfunctions.net/locate',
};