/* eslint-disable no-var */
/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';

import {
	AuthenticatedState,
	FacialPropsState,} from 'states';
import { useRecoilState, useRecoilValue } from 'recoil';
import './animation.css';
import { FaceRecognition, FaceDetection } from 'hooks';
import { useFace } from 'hooks';
import './face-auth.scss';
import './animation.css';
import { RenderFaceMatch } from './components';

export const FaceAuth = ({ onSuccess }: any) => {
	// recoil states
	// const setSignInActiveStep = useSetRecoilState(SignInActiveStepState);
	const [currentUserImage, setCurrentUserImage] = useState('');
	// const [recipientData, setRecipientData] = useState<any>({});
	const facialPropsState = useRecoilValue(FacialPropsState);
	const [authenticated, setAuthenticated] = useRecoilState(AuthenticatedState);

	// local states
	const [isCompleted] = useState(false);
	const [isLoading] = useState(false);
	const [loadModal] = useState(true);
	// const [title, setTitle] = useState('Facial Recognition');
	const loading = isLoading && loadModal;
	// const [updateStatus, setUpdateStatus] = useState(true);
	// const [senderImage, setSenderImg] = useState('');
	// const [recipientImg, setRecipientImg] = useState('');
	const [recipientLiveness, setRecipientLiveness] = useState(false);
	// const setRecipientImage = useSetRecoilState(RecipientImageState);

	const { setupCanvas } = useFace();
	const getUserImage = useCallback(() => {
		const canvas = (document.getElementById('output') ??
			document.getElementById('initial-canvas-output')) as HTMLCanvasElement;
		const video = document.getElementById('video') as HTMLVideoElement;
		canvas
			?.getContext('2d')
			?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
		return canvas?.toDataURL('image/png');
	}, []);

	useEffect(() => {
		console.log('useEffect is running');
		let size: number;
		if (window.innerWidth <= 760) {
			size = 350;
		} else {
			size = 450;
		}
		// TODO: remove timeout and will fix (convas - null issue)
		setTimeout(() => {
			setupCanvas(size);
			FaceDetection.runBlinking = true
		}, 1000);
		// TODO @ARUN: if data is matched... show next button
		// FaceRecognition.emitter.on('faceMatch', async (data: boolean) => {
		// 	console.log('faceMatchData', data);
		// 	if (!facialPropsState.is_recipient) {
		// 		setAuthenticated(data);
		// 	}
		// });

		// FaceRecognition.emitter.on('senderImg', async (base64Image: string) => {
		// 	if (base64Image && !facialPropsState.is_recipient) {
		// 		FaceDetection.recipientCheck = false;
		// 		const canv: any = document.getElementById('output');
		// 		const base64 = canv?.toDataURL();
		// 		if (!facialPropsState.is_recipient) {
		// 			setSenderImg(base64);
		// 		}
		// 	}
		// });

		FaceRecognition.emitter.on('eyeBlinkButtonEnable', (btnEnable: boolean) => {
			// eslint-disable-next-line no-console
			if (facialPropsState.is_recipient) {
				setAuthenticated(btnEnable);
			// 	setTimeout(async () => {
			// 		const image = await getUserImage();
			// 		console.log('getImageRecipient', image);
			// 		// setRecipientImage(image);
			// 	}, 500);
			}
			if (!facialPropsState.is_recipient) {
				setRecipientLiveness(btnEnable);
			}
		});

		// //senderImg1
		// FaceRecognition.emitter.on('senderImg1', async (base64Image: string) => {
		// 	if (
		// 		updateStatus &&
		// 		base64Image.length > 1000 &&
		// 		facialPropsState.is_recipient
		// 	) {
		// 		const canv: any = document.getElementById('output');
		// 		const base64 = canv?.toDataURL();
		// 		if (facialPropsState.is_recipient) {
		// 			setRecipientImg(base64);
		// 		}
		// 		FaceDetection.recipientCheck = false;
		// 	}
		// });
	}, [FaceRecognition]);

	// need to discuss with shuabham sir
	//this function will give the status of match and next btn
	// const handleGoBack = useCallback(() => {
	// 	setSignInActiveStep('chooseMethod');
	// }, []);

	useEffect(() => {
		if (authenticated && !currentUserImage) {
			setTimeout(async () => {
				const image = await getUserImage();
				// console.log('getImage', image);
				// setRecipientImage(image);
				setCurrentUserImage(image ?? '');
			}, 500);
		}
	}, [authenticated, currentUserImage]);

	useEffect(() => {
		if (facialPropsState.is_recipient) {
			FaceDetection.recipientCheck = true;
		} else {
			// FaceRecognition.faceBypassCheck = false;
			FaceRecognition.matchCount = 0;
			FaceDetection.runFaceMatcher = true;
		}
	}, []);

	return (
		<RenderFaceMatch
			loading={loading}
			currentUserImage={currentUserImage}
			isCompleted={isCompleted}
			// recipientData={recipientData}
			// handleSuccess={handleSuccess}
			onSuccess={onSuccess}
			// handleRecipientResponse={SumbitRecipientResponse}
			setTitle={() => ({})}
			recipientLiveness={recipientLiveness}
			// recipientImg={recipientImg}
			// setUpdateStatus={setUpdateStatus}
		/>
	);
};
