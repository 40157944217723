/* eslint-disable no-console */
import { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { jackRecipientDetails, jackTranstionKeys } from './states/jackstate';
import { jackActiveStepsState } from 'states';

export const WaitingScreen = () => {
	const IMAGE_GCP_URL =
		'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media';

	const setJackActiveSteps = useSetRecoilState(jackActiveStepsState);
	const jackTranstion = useRecoilValue(jackTranstionKeys);
	const setjackRecipientDetails = useSetRecoilState(jackRecipientDetails);

	const { receiver, sender } = jackTranstion ?? {};

	useEffect(() => {
		const checkUser = setInterval(async () => {
			const apiUrl = `https://api.stage.satschel.com/v2/rocket/customer/code/${receiver}`;
			const headers = {
				// Include any headers if needed
			};

			fetch(apiUrl, {
				method: 'GET',
				headers: headers,
			})
				.then((response) => {
					// Check if the request was successful (status code 200)
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.json();
				})
				.then((responseData) => {
					// Update the state with the response data
					console.log('completed----recipent', responseData);
					if (
						responseData?.customer?.status === 'completed' ||
						responseData?.customer?.status === 'rejected' || responseData?.customer?.status === 'declined'
					) {
						setjackRecipientDetails(responseData);
						clearInterval(checkUser);
						setJackActiveSteps('jack-recipient-details');
					}
				})
				.catch((error) => {
					console.error('Error making GET request:', error.message);
				});
		}, 2000);
	}, [receiver]);

	const renderSection = useMemo(() => {
		return (
			<div className="waiting-screen-box">
				<img
					className="waiting-loader"
					src={`${IMAGE_GCP_URL}/simpliciGif.gif`}
					alt="loder"
				/>
				<p className='waiting-screen-desp'>
					A text message has been sent to the recipient to verify identity,
					after they confirm you will receive a text to approve payment. You can
					close this window or wait for them to complete.
				</p>
			</div>
		);
	}, [sender]);

	return renderSection;
};
