/* eslint-disable no-console */
import { FaceLandmarksDetector } from '@tensorflow-models/face-landmarks-detection';
import EventEmitter from 'events';
import { Camera } from 'views/face-auth/camera';
import { createDetector, STATE } from 'views/face-auth/shared';
import { detectBlinkingEyes } from 'views/face-auth/utils';
import { FaceRecognition } from './face-recognition';

export class FaceDetection {
	static camera?: Camera;
	static detector?: FaceLandmarksDetector;
	static rafId?: number;
	static runFaceMatcher = true;
	static recipientCheck = false;
	static emitter = new EventEmitter();
	static runBlinking = false;
	static isRecipient = false;

	static async init() {
		// STEP 1: Camera constructor
		FaceDetection.camera = new Camera();
		const [, detector] = await Promise.all([
			FaceDetection.camera?.setupCamera(STATE.camera),
			createDetector(),
		]);
		// STEP 2: Camera setup
		// await FaceDetection.camera?.setupCamera(STATE.camera);

		// // STEP 3: Camera detector (mask)
		// FaceDetection.detector = await createDetector();
		FaceDetection.detector = detector;
		// STEP 4: render video and mask
		FaceDetection.renderPrediction();
	}

	static async renderPrediction() {
		await FaceDetection.renderResult();
		FaceDetection.rafId = requestAnimationFrame(FaceDetection.renderPrediction);
	}

	static async renderResult() {
		if ((FaceDetection.camera?.video?.readyState ?? 0) < 2) {
			await new Promise((resolve) => {
				if (FaceDetection.camera?.video) {
					FaceDetection.camera.video.onloadeddata = () => {
						resolve(FaceDetection.camera?.video);
					};
				}
			});
		}

		let faces;

		if (FaceDetection.detector != null) {
			try {
				faces = await FaceDetection.detector.estimateFaces(
					FaceDetection.camera?.video as HTMLVideoElement,
					{
						flipHorizontal: false,
					}
				);
			} catch (error) {
				FaceDetection.detector.dispose();
				FaceDetection.detector = undefined;
			}
		}

		// Show face video
		FaceDetection.camera?.drawCtx();

		if (faces?.[0]?.box) {
			const image = FaceDetection.camera?.getImage(faces?.[0]?.box);
			if (image && FaceRecognition.faceBypassCheck) {
				FaceRecognition.match(image);
			}

			// in case of recipientCheck
			if (image && FaceDetection.recipientCheck) {
				FaceRecognition.emitter.emit('senderImg1', image);
			}
		}
		// Show face mask
		if (faces && faces.length > 0 && !STATE.isModelChanged) {
			FaceDetection.camera?.drawMask(
				faces,
				STATE.modelConfig.triangulateMesh
				// STATE.modelConfig.boundingBox
			);
			detectBlinkingEyes(
				faces[0],
				FaceDetection.camera?.ctx,
				250,
				FaceRecognition.userName
			);
		}
	}
}
