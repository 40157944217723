/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { FaceDetection, FaceRecognition } from 'hooks';
//import { DateTime } from 'luxon';
// let last_eye_blink_time = DateTime.now().toSeconds();
// let eyeBlinkCount = 0;
// let facePosition = null;
const CLOSED_EYES_FRAME = 1;
let CEF_COUNTER = 0;
let TOTAL_BLINKS = 0;

const ratioWindow: any = [];
const WINDOW_SIZE = 30; // Assuming 30 frames per second (1 second = 30 frames)
let THRESHOLD_RATIO = 0.0;
let warmupCount = 0;
let mostRepeatedValue: any = 0;

function euclideanDistance(point: any, point1: any) {
	const { x, y, z } = point;
	const { x: x1, y: y1, z: z1 } = point1;
	const distance = Math.sqrt(
		Math.pow(x1 - x, 2) + Math.pow(y1 - y, 2) + Math.pow(z1 - z, 2)
	);

	return distance;
}

function calculateFaceSize(landmarks: any) {
	const leftCorner = landmarks[0]; // Example: Left corner of the face
	const rightCorner = landmarks[16]; // Example: Right corner of the face
	// Calculate the Euclidean distance between the two landmarks in 3D space
	const distance = Math.sqrt(
		Math.pow(rightCorner.x - leftCorner.x, 2) +
			Math.pow(rightCorner.y - leftCorner.y, 2) +
			Math.pow(rightCorner.z - leftCorner.z, 2)
	);

	return distance;
}

function blinkRatio(landmarks: any) {
	// const lhRight = landmarks[33]; //p1
	// const lhLeft = landmarks[133]; //p4
	// const lvTop = landmarks[159]; //p2
	// const lvBottom = landmarks[145];
	// const rhRight = landmarks[362];
	// const rhLeft = landmarks[263];
	// const rvTop = landmarks[386];
	// const rvBottom = landmarks[374];
	// const rhDistance = euclideanDistance(rhRight, rhLeft);
	// const rvDistance = euclideanDistance(rvTop, rvBottom);
	// const lvDistance = euclideanDistance(lvTop, lvBottom);
	// const lhDistance = euclideanDistance(lhRight, lhLeft);

	const lefteye = {
		p1: landmarks[33],
		p2: landmarks[160],
		p3: landmarks[158],
		p4: landmarks[133],
		p5: landmarks[153],
		p6: landmarks[144],
	};

	// const lefteye = {
	// 	p1: landmarks[33],
	// 	p2: landmarks[160],
	// 	p3: landmarks[159],
	// 	p4: landmarks[133],
	// 	p5: landmarks[145],
	// 	p6: landmarks[144],
	// };

	const EAR_3D_left =
		(euclideanDistance(lefteye.p2, lefteye.p6) +
			euclideanDistance(lefteye.p3, lefteye.p5)) /
		(2 * euclideanDistance(lefteye.p1, lefteye.p4));

	const righteye = {
		p1: landmarks[362],
		p2: landmarks[385],
		p3: landmarks[387],
		p4: landmarks[263],
		p5: landmarks[373],
		p6: landmarks[380],
	};

	const EAR_3D_right =
		(euclideanDistance(righteye.p2, righteye.p6) +
			euclideanDistance(righteye.p3, righteye.p5)) /
		(2 * euclideanDistance(righteye.p1, righteye.p4));

	const ratio = (EAR_3D_right + EAR_3D_left) / 2;

	return ratio;
}

function roundedRect(
	x: number,
	y: number,
	width: number,

	height: number,

	radius: number
) {
	return new Path2D(
		`M ${x + radius} ${y} H ${
			x + width - radius
		} a ${radius} ${radius} 0 0 1 ${radius} ${radius} V ${
			y + height - radius
		} a ${radius} ${radius} 0 0 1 ${-radius} ${radius} H ${
			x + radius
		} a ${radius} ${radius} 0 0 1 ${-radius} ${-radius} V ${
			y + radius
		} a ${radius} ${radius} 0 0 1 ${radius} ${-radius}`
	);
}

//Code for Face Size Detection

function findMostRepeatedValue(ratioWindow: number[]): number | null {
	const count: { [key: string]: number } = {};
	ratioWindow.forEach((value) => {
		const roundedValue = value.toFixed(1).toString();
		if (count[roundedValue]) {
			count[roundedValue]++;
		} else {
			count[roundedValue] = 1;
		}
	});

	let mostRepeatedValue: number | null = null;
	let highestCount = 0;
	Object.entries(count).forEach(([value, count]) => {
		if (count > highestCount) {
			highestCount = count;
			mostRepeatedValue = Number(value);
		}
	});
	return mostRepeatedValue;
}

function detectBlinkingEyes(
	face: any,
	ctx: any,
	videoWidth: any,
	name: string
) {
	const ratio = blinkRatio(face.keypoints);

	const faceSize = calculateFaceSize(face.keypoints);

	ratioWindow.push(ratio);

	if (ratioWindow.length > WINDOW_SIZE) {
		ratioWindow.shift();
	}
	if (ratioWindow.length === WINDOW_SIZE) {
		if (warmupCount < 3) {
			mostRepeatedValue = findMostRepeatedValue(ratioWindow);
		} else {
			THRESHOLD_RATIO = mostRepeatedValue + 0.5;
			if (THRESHOLD_RATIO > 10) {
				console.log(THRESHOLD_RATIO);
			}
		}
		warmupCount = warmupCount + 1;
	}

	// calculate eye aspect ratio for right and left eye

	//FaceRecognition.earBlinkRatio = ear;

	// This threshold might need to be adjusted for different camera resolutions
	//const isBlinking = ear < 0.2 ? true : false;
	if (!FaceDetection.runBlinking) return;
	if (!ctx) return;
	const box = face.box;
	ctx.save();
	ctx.translate(videoWidth, 0);
	ctx.scale(-1, 1);
	// this fuction use for blinking ratio

	if (faceSize > 10 && faceSize < 50) {
		FaceRecognition.earBlinkRatio = ratio;
		const thresholdRatio = FaceDetection.isRecipient ? 0.22 : 0.2

		if (ratio < thresholdRatio) {
			CEF_COUNTER += 1;
		} else {
			if (CEF_COUNTER > CLOSED_EYES_FRAME) {
				TOTAL_BLINKS += 1;
				CEF_COUNTER = 0;
			}
		}
		FaceRecognition.blinkCounter = TOTAL_BLINKS;
		FaceDetection.emitter.emit('eyeBlinkCount', TOTAL_BLINKS);
		if (TOTAL_BLINKS >= 2) {
			FaceRecognition.eyeBlinkRatio = ratio;
			FaceRecognition.faceKeyPoints = face.keypoints[0];
			FaceRecognition.emitter.emit('eyeBlink', 'yellowLiveness');
			FaceRecognition.emitter.emit('eyeBlinkButtonEnable', true);
			// eye is blinking
			ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
			ctx.fillRect(
				videoWidth - box.xMax - 35,
				box.yMax + 10,
				box.width + 60,
				50,
				8
			);
			ctx.font = '16px  Arial';
			ctx.fillStyle = 'yellow';
			ctx.textAlign = 'center';
			ctx.fillText(
				'liveness: live',
				videoWidth - box.xMax + box.width / 2,
				box.yMax + 30
			);

			if (name !== 'unknown') {
				ctx.font = '16px  Arial';
				ctx.fillStyle = 'yellow';
				ctx.textAlign = 'center';
				ctx.fillText(
					`Name: ${name}`,
					videoWidth - box.xMax + box.width / 2,
					box.yMax + 50
				);
			}
		} else {
			ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
			ctx.fill(
				roundedRect(
					videoWidth - box.xMax - 60,
					box.yMax + 10,
					box.width + 120,
					50,
					8
				)
			);

			ctx.font = '16px  Arial';
			ctx.fillStyle = '#FF00FF';
			ctx.textAlign = 'center';
			ctx.fillText(
				'liveness: Spoof (Please Blink)',
				videoWidth - box.xMax + box.width / 2,
				box.yMax + 35
			);

			if (name !== 'unknown') {
				ctx.font = '16px  Arial';
				ctx.fillStyle = '#FF00FF';
				ctx.textAlign = 'center';
				ctx.fillText(
					`Name: ${name}`,

					videoWidth - box.xMax + box.width / 2,

					box.yMax + 50
				);
			}
		}
	} else {
		// TOTAL_BLINKS = 0;
		// CEF_COUNTER = 0;
		ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';

		ctx.fill(
			roundedRect(
				videoWidth - box.xMax - 60,
				box.yMax + 10,
				box.width + 120,
				50,
				8
			)
		);

		ctx.font = '16px  Arial';
		ctx.fillStyle = '#FF00FF';
		ctx.textAlign = 'center';

		if (faceSize < 10) {
			FaceRecognition.emitter.emit('eyeBlink', 'pinkEye_Far');
		} else if (faceSize > 50) {
			FaceRecognition.emitter.emit('eyeBlink', 'pinkEye_Close');
		}

		ctx.fillText(
			'liveness: Spoof (Please Blink)',
			videoWidth - box.xMax + box.width / 2,
			box.yMax + 40
		);

		if (name !== 'unknown') {
			ctx.font = '16px  Arial';
			ctx.fillStyle = '#FF00FF';
			ctx.textAlign = 'center';
			ctx.fillText(
				`Name: ${name}`,
				videoWidth - box.xMax + box.width / 2,
				box.yMax + 50
			);
		}
	}
	ctx.restore();
}

export { detectBlinkingEyes };
