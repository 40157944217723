/* eslint-disable react/jsx-one-expression-per-line */
import { Button } from '@storybook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { jackActiveStepsState } from 'states';
import './verification-auth.scss';
// import userAvatarImg from '../../views/face-auth/asests/user_avatar.png';
import { Address, GoogleLocation, jackRecipientDetails } from './states/jackstate';
import { useGetLocationDetails } from './hooks/use-location';

export const JackRecipientDetailsScreen = () => {
	const setJackActiveSteps = useSetRecoilState(jackActiveStepsState);
	const jackRecipient = useRecoilValue(jackRecipientDetails);
	const [, setLocation] = useState('--');
	const googleLocation = useRecoilValue(GoogleLocation);
	
	const { formatted_address = '' } = useMemo(
		() => googleLocation?.address ?? ({} as Address),
		[googleLocation?.address]
	);
	const { user, customer } = useMemo(()=>jackRecipient ?? {},[jackRecipient]);
	const {latitude,longitude} = useMemo(()=>customer?.device_info?.location ?? {},[customer?.device_info?.location])

	const { getGeoInfo } = useGetLocationDetails();

	const BtnClick = useCallback(() => {
		setJackActiveSteps('sender-verification-screen');
	}, []);

	const renderStatus = useMemo(() => {
		if (customer?.status === 'rejected') {
			return 'Note: Recipient authentication failed';
		} else if (customer?.status === 'declined') {
			return 'Note: Recipient declined your request';
		}
		return '';
	}, []);

	useEffect(() => {
		if (
			latitude &&
			longitude &&
			!googleLocation
		) {
			getGeoInfo({
				lat: latitude,
				lng: longitude,
			});
		}
	}, []);

	useEffect(() => {
		fetch(
			'https://nominatim.openstreetmap.org/reverse?format=json&lat=' +
				customer?.device_info?.location?.latitude +
				'&lon=' +
				customer?.device_info?.location?.longitude
		)
			.then((response) => response.json())
			.then((data) => {
				if (data) {
					setLocation(data.display_name);
				}
			})
			.catch(() => {
				// handle error
				setLocation('--');
			});
	}, [customer]);

	const handleDisable = useMemo(() => {
		if (customer?.status === 'rejected' || customer?.status === 'declined') {
			return true;
		}
		return false;
	}, []);

	const renderImage = useMemo(() => {
		return (
			<img
				className="jack-recipitent-img"
				src={
					customer?.user_image === ''
						? 'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media/AvatarDefault-Light.svg'
						: customer?.user_image
				}
				alt="img"
			/>
		);
	}, [customer]);

	return (
		<div className="jack-recipient-wrapper">
			<div className="jack-recipient-heading">Recipient Details</div>
			{renderImage}

			<div className="jack-info-wrapper">
				<div className="jack-info-label">Name :</div>{' '}
				<div>{user?.name ?? '--'}</div>
			</div>
			<div className="jack-info-wrapper">
				<div className="jack-info-label">Email :</div>
				<div>{user?.email_id ?? '--'}</div>
			</div>
			<div className="jack-info-wrapper">
				<div className="jack-info-label">Phone :</div>
				<div>{user?.phone_no ?? '--'}</div>
			</div>
			<div className="jack-location-info-wrapper">
				<div className="jack-info-label">Location :</div>
				<div
					data-tooltip={
						formatted_address?.length > 120 ? formatted_address : null
					}
				>
					<div className="jack-location-label">{formatted_address ?? '--'}</div>
				</div>
			</div>
			<div className="jack-recipient-status-note">{renderStatus}</div>
			<Button
				width="350px"
				handleClick={BtnClick}
				label={'Continue'}
				type="button__filled--primary button__large button__block button-jack-infor"
				disabled={handleDisable}
			></Button>
		</div>
	);
};
