import { atom } from 'recoil';

export interface ILocation {
	accuracy: number;
	altitude: number | null;
	altitudeAccuracy: number | null;
	latitude: number;
	longitude: number;
}

interface AddressComponent {
	long_name: string;
	short_name: string;
	types: string[];
}

interface Country {
	country: string;
	code: string;
	iso: string;
}

interface Location {
	lat: number;
	lng: number;
}

interface Northeast {
	lat: number;
	lng: number;
}

interface Southwest {
	lat: number;
	lng: number;
}

interface Viewport {
	northeast: Northeast;
	southwest: Southwest;
}

interface Geometry {
	location: Location;
	location_type: string;
	viewport: Viewport;
}

export interface Address {
	address_components: AddressComponent[];
	formatted_address: string;
	geometry: Geometry;
	place_id: string;
	types: string[];
}

export interface LocationInfo {
	ip: string;
	country: Country;
	address: Address;
}

export const jackTranstionKeys = atom<any>({
	key: 'jack-transctions-keys-state',
	default: {},
});

export const jackRecipientDetails = atom<any>({
	key: 'jack-recipient-details',
	default: {},
});

export const UserLocationState = atom<ILocation | null>({
	key: 'config-user-location-info-state-key',
	default: null,
});

export const GoogleLocation = atom<null | LocationInfo>({
	key: 'google-location-state',
	default: null,
});
