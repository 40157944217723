/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from 'react';
import './camera-setting.scss';
import { ChromseStep, EdgeStep, SafariStep } from './instructions';

export const CameraSettings = () => {
	const userAgent = navigator.userAgent;
	const [browserType, setbrowserType] = useState('');
	useEffect(() => {
		if (userAgent.includes('Chrome')) {
			setbrowserType('Chrome');
		} else if (userAgent.includes('Firefox')) {
			setbrowserType('Firefox');
		} else if (userAgent.includes('Safari')) {
			setbrowserType('Safari');
		} else if (userAgent.includes('Edge')) {
			setbrowserType('Edge');
		} else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
			setbrowserType('Opera');
		} else if (userAgent.includes('Trident') || userAgent.includes('MSIE')) {
			setbrowserType('Chrome');
		} else {
			setbrowserType('Chrome');
		}
	}, []);

	return (
		<div className="settings">
			<div className="settings__wrapper">
				<div>
					<i className="settings__camera-icon ri-camera-off-fill"></i>
				</div>
				your camera permission is OFF
			</div>
			{browserType === 'Safari' && (
				<div className="settings__camera-instructions">
					<div>Follow these steps to allow :-</div>
					{SafariStep}
				</div>
			)}
			{browserType === 'Chrome' && (
				<div className="settings__camera-instructions">
					<div>Follow these steps to allow :-</div>
					{ChromseStep}
				</div>
			)}
			{browserType === 'Edge' && (
				<div className="settings__camera-instructions">
					<div>Follow these steps to allow :-</div>
					{EdgeStep}
				</div>
			)}
			{browserType === 'Opera' && (
				<div className="settings__camera-instructions">
					<div>Follow these steps to allow :-</div>
					{EdgeStep}
				</div>
			)}
			{/* <div className='settings__center'>
			<Button
			width='320px'
				label="Go to Login"
				type={'button__filled--primary button__large button__block'}
				handleClick={() => window.location.reload()}
			/>
			</div> */}
		</div>
	);
};
