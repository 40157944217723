import React from 'react';
import * as ReactDOM from 'react-dom/client';
import reactToWebComponent from 'react-to-webcomponent';
import { Main } from 'main';

const BiometricContainer = reactToWebComponent(
  Main as any,
  React as any,
  ReactDOM as any,
  {
    props: {
      on_back: Function,
      on_success: Function,
      on_failed: Function,
      mobile_number: String,
      country_code: String,
      face_recognition: String,
      is_redirect_auth: String,
      from_bank: String,
      is_verified:String
    },

  }
);

customElements.define('biometric-auth', BiometricContainer as any);

