import { useCallback, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Button, ICountries, Input, Loader } from '@storybook';
import { FacialPropsState, SignInActiveStepState } from 'states';
import style from './signin-form.module.sass';
import './signin-form.scss';
import { FaceRecognition, useNetwork } from 'hooks';
import { toast } from 'react-toastify';

export const SignInForm = () => {
	const [facialPropsState, setFacialPropsState] =
		useRecoilState(FacialPropsState);
	// const setFacialData = useSetRecoilState(FacialDataState)

	const { post, loading } = useNetwork();

	const setSignInActiveStep = useSetRecoilState(SignInActiveStepState);
	const handleCountryCode = useCallback((e: ICountries) => {
		const value = e.label;
		setFacialPropsState((prevState: any) => {
			const newState = { ...prevState, countryCode: value };
			return newState;
		});
	}, []);

	const handleOnClick = useCallback(() => {
		post('/biometrics/get?type=arc-facial', {
			phone: facialPropsState?.mobileNumber ?? '6397382727',
			countryCode: facialPropsState?.countryCode ?? '+91',
		})
			.then((data) => {
				if (data) {
					FaceRecognition.setupModal(data);
				}
				if (data.descriptors.length === 0) {
					toast.error('Your face is not registerd, Please do KYC');
				} else {
					setSignInActiveStep('chooseMethod');
				}
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.log('Error', err);
				toast.error('Your face is not registerd, Please do KYC');
			});
	}, [facialPropsState]);

	const onHandleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement> | any) => {
			const { value } = e.target;
			const re = /^[0-9\b]+$/;
			//accept only number and value ==='' is used as first char should be remove in input field
			if (re.test(value) || value === '') {
				setFacialPropsState((prevState: any) => {
					const newState = { ...prevState, mobileNumber: value };
					return newState;
				});
			}

			if (e.keyCode === 13) {
				handleOnClick();
			}
		},
		// eslint-disable--line react-hooks/exhaustive-deps
		[facialPropsState]
	);

	const isDisabled = useMemo(() => {
		if (facialPropsState?.mobileNumber?.length > 5) return false;
		return true;
	}, [facialPropsState]);

	const manageButtonLabel = useMemo((): string | JSX.Element => {
		if (loading) {
			return <Loader className="loader-white" dimension={14} />;
		}
		return 'Next';
	}, [loading]);

	return (
		<div className={style.wrapper}>
			<div className={style.inner_wrapper}>
				<div className={style.inner}>
					<div>
						<div className={style.title}>Enter Your Mobile Number for</div>
						<div className={style.sub_title}>
							{facialPropsState.is_recipient
								? 'Please login as recipient '
								: 'Please login as sender'}
						</div>
					</div>

					{/* <form className={style.form_wrapper} autoComplete="on"> */}
					<div className={`${style.input_wrapper} input-wrapper`}>
						<Input
							handleChange={onHandleChange}
							inputType="text" //keet type text as number is not giving suggestions for auto fill will use regex to accept number
							value={facialPropsState?.mobileNumber}
							placeholder="XXXXXXXXXX"
							label=""
							handleChangeCountry={handleCountryCode}
							isCountryCodeVisible={true}
							countryCode={facialPropsState?.countryCode as string}
							autoComplete="tel"
							inputName="phone"
						/>
					</div>

					<Button
						type={'button__filled--primary button__large button__block'}
						handleClick={handleOnClick}
						label={manageButtonLabel}
						disabled={isDisabled}
					/>
					{/* </form> */}
				</div>
			</div>
		</div>
	);
};
