/* eslint-disable no-var */
import { Button } from '@storybook';
import {
	FaceDetection,
	FaceRecognition,
	useNetwork,
	useWebAuthentication,
} from 'hooks';
import {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	AuthenticatedState,
	BlinkcounterState,
	FacialPropsState,
	recipientIdState,
	RecipientImagesArrayState,
	RecipientImageState,
	recipitentDetailsState,
	senderDetailsState,
	SenderImageState,
	// recipitentRejectedState,
	SignInActiveStepState,
	userLocationState,
} from 'states';
// import { WaitingApproval } from './waiting-approval';
import './render-face.css';
import { ShowWatingApprovalState } from '../stores';
import LoaderSagment from '../faceLoader/loaderSegment';
import { SuccussApproval } from './success-approval';
import { API_URL } from 'constant';
import { GetQueryParams } from 'utils';
// import { FaceDetection } from '@vladmandic/face-api';

export const RenderFaceMatch = ({
	loading,
	currentUserImage,
	isCompleted,
	// recipientData,
	// handleSuccess,
	onSuccess,
	setTitle,
	recipientImg,
	setUpdateStatus,
	recipientLiveness,
}: any) => {
	const { patch } = useNetwork();
	const recipientUserId = useRecoilValue(recipientIdState);
	const recipitentDetails = useRecoilValue(recipitentDetailsState);
	const address = useRecoilValue(userLocationState);
	const userImg = useRef('');
	const stopWebAuth = useRef(true);
	const [blinkCount, setBlinkCount] = useRecoilState(BlinkcounterState);

	const { getRegistrations, registerNewCredentials, authenticate } =
		useWebAuthentication();
	const setSignInActiveStep = useSetRecoilState(SignInActiveStepState);
	const authenticated = useRecoilValue(AuthenticatedState);
	const { mobileNumber } = useRecoilValue(FacialPropsState);
	const facialPropsState = useRecoilValue(FacialPropsState);
	const showWatingApproval = useRecoilValue(ShowWatingApprovalState);
	const recipientImage = useRecoilValue(RecipientImageState);
	const senderImage = useRecoilValue(SenderImageState);
	const senderDetails = useRecoilValue(senderDetailsState);
	const recipientArray = useRecoilValue(RecipientImagesArrayState);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [recipientIP, setrecipientIP] = useState('');

	// const [stopWebAuth , setStopWebAuth]= useState(true)
	// const recipitentRejected = useRecoilValue(recipitentRejectedState)
	const [status, setStatus] = useState(
		'Please blink twice for liveness detection'
	);
	const imageDataRef = useRef('');
	const handleGoBack = useCallback(() => {
		const statusId = GetQueryParams('status');
		if (facialPropsState?.is_recipient) {
			setSignInActiveStep('recipient-approval');
			return;
		}
		if (statusId) {
			setSignInActiveStep('sender-check');
			return;
		}
		setSignInActiveStep('chooseMethod');
	}, []);
	const width = window.innerWidth < 500 ? window.innerWidth : 500;

	const handleFail = useCallback(() => {
		<></>;
	}, []);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleCloseCamera = async () => {
		const videoElement = document.getElementById('video');
		// const stream = videoElem.srcObject;
		if (videoElement instanceof HTMLVideoElement) {
			const stream = videoElement.srcObject;

			// Check if the stream exists
			if (stream instanceof MediaStream) {
				// Get all tracks from the stream
				const tracks = stream.getTracks();

				// Stop each track
				tracks.forEach((track: { stop: () => void }) => {
					track.stop();
				});

				// Clear the video element source object
				videoElement.srcObject = null;
			}
		}
	};

	// const getUserImage = useCallback(() => {
	// 	if (currentUserImage === '') {
	// 		const canvas = (document.getElementById('output') ??
	// 			document.getElementById('initial-canvas-output')) as HTMLCanvasElement;
	// 		const video = document.getElementById('video') as HTMLVideoElement;
	// 		canvas
	// 			?.getContext('2d')
	// 			?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
	// 		return canvas?.toDataURL('image/png').split(';base64,')?.[1];
	// 	} else {
	// 		return currentUserImage;
	// 	}
	// }, [currentUserImage]);
	//@Anuj When Backend will provide extra payload then uncomment it
	useEffect(() => {
		fetch('https://api.ipify.org?format=json')
			.then((response) => response.json())
			.then((data) => {
				setrecipientIP(data.ip);
			})
			.catch((error) => error);
	}, []);

	const capture = (video: any, scaleFactor: any) => {
		if (scaleFactor == null) {
			scaleFactor = 1;
		}
		var w = video.videoWidth * scaleFactor;
		var h = video.videoHeight * scaleFactor;
		var canvas = document.createElement('canvas');
		canvas.width = w;
		canvas.height = h;
		var ctx = canvas.getContext('2d');
		if (ctx) {
			ctx.drawImage(video, 0, 0, w, h);
		}
		return canvas;
	};

	const successCallback = useCallback(() => {
		// console.log('recipientImage', recipientImage);
		// var video = document.getElementById('video');
		// var imageData = capture(video, 0.8).toDataURL();
		// eslint-disable-next-line no-console

		if (facialPropsState.is_recipient) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const payload = {
				status: 'complete',
				recipientImage: [imageDataRef.current], // todo send array of images
				recipientLocation: {
					accuracy: address?.accuracy,
					altitude: address?.altitude,
					latitude: address?.latitude,
					longitude: address?.longitude,
				},
				recipientPhone: senderDetails.phone,
				recipientIP: recipientIP ?? '',
				recipientOS: navigator.platform ?? 'NA',
			};

			patch(
				API_URL.REQUEST_RECIPIENT + '/' + facialPropsState.isrecipient_id,
				payload
			)
				.then((resp) => {
					if (resp) {
						setUpdateStatus(false);
						handleCloseCamera();
					}
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.error(error);
					handleCloseCamera();
				});
			return;
		}
		if (!facialPropsState.is_recipient) {
			const payload = {
				senderStatus: 'completed',
			};

			patch(API_URL.REQUEST_RECIPIENT + '/' + recipientUserId, payload)
				.then((resp) => {
					if (resp) {
						setUpdateStatus(false);
						handleCloseCamera();
					}
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.log(error);
					handleCloseCamera();
				});
		}
		const recipient = {
			recipient_img: recipitentDetails.recipientImage,
			recipient_id: recipitentDetails._id,
		};
		// setStopWebAuth(false)
		setSignInActiveStep('success-screen');
		onSuccess(senderImage.split(';base64,')?.[1], recipient);
	}, [
		currentUserImage,
		address,
		onSuccess,
		recipitentDetails._id,
		recipitentDetails.recipientImage,
		recipientImg,
		recipientUserId,
		recipientImage,
		senderImage,
		senderDetails,
		recipientArray,
		imageDataRef.current,
	]);

	//   after arc face
	const handleSenderFlow = useCallback(() => {
		stopWebAuth.current = false;
		const isAlreadyExist = getRegistrations().find(
			(item: any) => item.name === mobileNumber ?? ''
		);
		const registerPayload = {
			email: mobileNumber ?? '',
			id: mobileNumber ?? '',
			displayName: mobileNumber ?? '',
		};
		const video = document.getElementById('video');
		const imageData = capture(video, 0.8).toDataURL();
		imageDataRef.current = imageData;

		if (!isAlreadyExist) {
			registerNewCredentials(successCallback, registerPayload);
		} else {
			authenticate(successCallback, handleFail);
		}

		// For recipient Side we are sumbiting response
	}, [recipientImg, successCallback, address]);

	const renderButton = useMemo(() => {
		return (
			<div className="buttonGroup">
				<Button
					type="button__filled--secondary button__large button__block"
					label={'Back'}
					height="42px"
					width="100px"
					handleClick={handleGoBack}
				/>
				{/* {facialPropsState.is_recipient ? (
                    <Button
                        type="button__filled--primary button__large button__block"
                        label={'Next'}
                        handleClick={() => handleSenderFlow()}
                        height="42px"
                        width="100px"
                        disabled={!authenticated}
                    />
                ) : (
                    <Button
                        type="button__filled--primary button__large button__block"
                        label={'Next'}
                        handleClick={() => handleSenderFlow()}
                        height="42px"
                        width="100px"
                        disabled={!(authenticated && recipientLiveness)}
                    />
                )} */}
			</div>
		);
	}, [authenticated, recipientLiveness]);

	// before arc face
	// const handleNextPay = useCallback(() => {
	//  const recipient = {
	//      recipient_img: recipientData.image,
	//      recipient_id: recipientData._id,
	//  };
	//  onSuccess(currentUserImage, recipient);
	// }, [currentUserImage, onSuccess, recipientData._id, recipientData.image]);

	FaceRecognition.emitter.on('eyeBlink', (color: string) => {
		switch (color) {
			case 'pinkEye_Far':
				setStatus('Please come closer to the camera');
				break;
			case 'pinkEye_Close':
				setStatus('Please step back from camera');
				break;
			case 'pinkEyeBlink':
				setStatus('Please blink twice for liveness detection');
				break;
			case 'turnLeft':
				setStatus('Please turn left once for liveness detection');
				break;
			case 'turnRight':
				setStatus('Please turn right once for liveness detection');
				break;
			case 'openMouth':
				setStatus('Please open your mouth once for liveness detection');
				break;
			case 'yellowLiveness':
				if (facialPropsState.is_recipient) {
					setStatus('liveness detected');
				}
				if (!facialPropsState.is_recipient) {
					setStatus(
						authenticated
							? 'liveness detected'
							: 'liveness detected but Face not matched'
					);
					// setTimeout(() => {
					//  handleGoBack();
					// }, 1000);
				}
				break;
			default:
				setStatus('Please check your camera');
				break;
		}
	});

	useEffect(
		() => {
			FaceDetection.emitter.on('eyeBlinkCount', (eyeBlinkCount: number) => {
				setBlinkCount(eyeBlinkCount);
				// if (facialPropsState?.is_recipient) {
				//  if (stopWebAuth.current && eyeBlinkCount == 2) {
				//      userImg.current = recipientImg;
				//      setTimeout(() => {
				//          handleSenderFlow();
				//      }, 1000);
				//  }
				// }
				// if (!facialPropsState?.is_recipient) {
				//  if (stopWebAuth.current && eyeBlinkCount == 2 && authenticated) {
				//      userImg.current = recipientImg;
				//      setTimeout(() => {
				//          handleSenderFlow();
				//      }, 1000);
				//  }
				// }

				switch (eyeBlinkCount) {
					case 0:
						setStatus('Please blink twice for liveness detection');
						break;
					case 1:
						setStatus('Please blink one more time ');
						break;
					default:
				}
			});
		},

		[
			// FaceRecognition,
			// stopWebAuth.current,
			// facialPropsState,
			// recipientImg,
			// authenticated,
		]
	);

	useEffect(() => {
		if (facialPropsState?.is_recipient) {
			if (
				stopWebAuth.current &&
				blinkCount >= 2 &&
				status === 'liveness detected'
			) {
				if (recipientImg) {
					userImg.current = recipientImg;
				}

				setTimeout(() => {
					handleSenderFlow();
				}, 1000);
			}
		}
		if (!facialPropsState?.is_recipient) {
			if (
				stopWebAuth.current &&
				blinkCount >= 2 &&
				authenticated &&
				status === 'liveness detected'
			) {
				if (recipientImg) {
					userImg.current = recipientImg;
				}
				// userImg.current = sendImage?.toDataURL() ?? '';
				setTimeout(() => {
					handleSenderFlow();
				}, 1000);
			}
		}
	}, [blinkCount, status]);

	return (
		<>
			{!isCompleted && !showWatingApproval && (
				<Fragment>
					<div
						className="container"
						style={{
							display: loading ? 'none' : 'flex',
						}}
					>
						{/* <div>{`Ratio : ${FaceRecognition.earBlinkRatio}`}</div>
						<div>{`Blink Counter : ${FaceRecognition.blinkCounter}`}</div>
						<div>
							{FaceRecognition.faceKeyPoints && (
								<p>
									{`  
						   Z :${FaceRecognition.faceKeyPoints?.z}
						   `}
								</p>
							)}
						</div> */}
						<div className="canvas-container">
							<div className="canvas-wrapper">
								<div className="blink-message-wrap">
									<span className="blink-message">{status}</span>
									{/* <h3
										key={FaceRecognition.eyeBlinkRatio}
									>{`Blinking Ratio ${FaceRecognition.eyeBlinkRatio}`}</h3> */}
								</div>
								<div className="loaderWrapper">
									<LoaderSagment />
								</div>
							</div>
						</div>
						<div className="renderButton">{renderButton}</div>
					</div>
				</Fragment>
			)}
			{showWatingApproval && facialPropsState.is_recipient && (
				<SuccussApproval width={width} setTitle={setTitle} />
			)}
		</>
	);
};
