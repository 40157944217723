/* eslint-disable no-console */
/* eslint-disable no-mixed-spaces-and-tabs */
import { useLocation } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { recipitentDetailsState, userLocationState } from 'states';
import { RecipitentDetails } from 'views/face-auth/components';
import { FaceAuth } from 'views/face-auth/face-auth';
import { FacialPropsState, SignInActiveStepState } from '../../states/signin';
import {
	CameraSettings,
	RecipientApprovalScreen,
	SignInForm,
	SuccessScreen,
} from './components';
import { ChooseMethod } from './components/choose-method';
import { GetQueryParams } from 'utils';
import './Signin.scss';

export const SignIn = ({ onSuccess }: any) => {
	//globle states
	const facialPropsState = useRecoilValue(FacialPropsState);
	// local states
	const [ipAddress, setIpAddress] = useState('');
	const recipitentDetails = useRecoilValue(recipitentDetailsState);
	const { recipientLocation } = recipitentDetails;
	const setAddress = useSetRecoilState(userLocationState);

	//hooks
	const { locationInfo, fetchLocation } = useLocation();

	const width = '100%';

	useEffect(() => {
		fetch('https://api.ipify.org?format=json')
			.then((response) => response.json())
			.then((data) => {
				setIpAddress(data.ip);
			})
			.catch((error) => error);
	}, []);

	useEffect(() => {
		if (!Object.keys(locationInfo ?? {}).length) {
			fetchLocation();
		}
		setAddress(locationInfo);
	}, [locationInfo]);

	const [signInActiveStep, setSignInActiveStep] = useRecoilState(
		SignInActiveStepState
	);

	const handleFaceLoginSuccess = useCallback(
		async (res: any, recipient_data: any) => {

			
			const fbAmount = (
				document
					?.querySelector(
						'body > banno-web > bannoweb-layout > bannoweb-zelle-send'
					)
					?.shadowRoot?.querySelector?.('#frmEnterAmount')
					?.shadowRoot?.querySelector?.('#amount') as
					| HTMLInputElement
					| undefined
			)?.value?.toString();
			const amount = fbAmount;

			const payload = {
				matched: res ?? '',
				recipient_name : recipitentDetails?.name ?? "NA" ,
				location: {
					sender: {
						accuracy: locationInfo?.accuracy,
						altitude: locationInfo?.altitude,
						altitudeAccuracy: locationInfo?.altitudeAccuracy,
						latitude: locationInfo?.latitude,
						longitude: locationInfo?.longitude,
					},
					recipient: {
						accuracy: recipientLocation?.accuracy,
						altitude: recipientLocation?.altitude,
						altitudeAccuracy: recipientLocation?.altitudeAccuracy,
						latitude: recipientLocation?.latitude,
						longitude: recipientLocation?.longitude,
					},
				},
				sender_ip: ipAddress,
				recipient_id: recipient_data.recipient_id ?? '',
				recipient_img: recipient_data.recipient_img ?? '',
				amount: amount ?? recipitentDetails.amount,
				recipientEmail : recipitentDetails.email ?? 'NA',
				sender_os :  navigator?.platform ?? "",
				recipient_ip : recipitentDetails.recipientIP ?? "",
				recipient_os : recipitentDetails.recipientOS ?? "",
				recipientPhone : recipitentDetails.phone ?? "",


			};
			onSuccess(payload);
		},
		[ipAddress, locationInfo, location, recipitentDetails]
	);

	const getComponent = useMemo(() => {
		switch (signInActiveStep) {
			case 'recipient-approval':
				return <RecipientApprovalScreen />;
			case 'success-screen':
				return <SuccessScreen />;
			case 'phone':
				return <SignInForm />;
			case 'chooseMethod':
				return <ChooseMethod fromBank={true} />;
			case 'face-recognize':
				return <FaceAuth onSuccess={handleFaceLoginSuccess} />;
			case 'sender-check':
				return (
					<RecipitentDetails
						width={width}
						handleNextPay={() => setSignInActiveStep('face-recognize')}
						isRecipient={true}
					/>
				);
			case "camera-settings":
				return <CameraSettings />
			default:
				return <SignInForm />;
		}
	}, [signInActiveStep]);

	const isClose = useMemo(() => {
		return !(
			signInActiveStep === 'recipient-approval' ||
			signInActiveStep === 'phone' ||
			(signInActiveStep === 'face-recognize' &&
				facialPropsState?.is_recipient) ||
			signInActiveStep === 'sender-check'
		);
	}, [signInActiveStep]);

	const handleCloseModal = useCallback(() => {
		const { onBack } = facialPropsState ?? {};
		const statusId = GetQueryParams('status');
		if (onBack) {
			onBack();
			return;
		}
		if (statusId) {
			setSignInActiveStep('sender-check');
			return;
		}
		setSignInActiveStep('phone');
	}, [facialPropsState]);

	return (
		<div className="component-wrapper">
			{isClose && signInActiveStep !== "camera-settings" && (
				<div onClick={handleCloseModal}>
					<i className="ri-close-line component-wrapper__close" />
				</div>
			)}
			{getComponent}
		</div>
	);
};
