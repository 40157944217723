
import { Bounce, ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import { GetQueryParams } from 'utils';
import { useEffect, useState } from 'react';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';
import 'react-toastify/dist/ReactToastify.css';

export const Main = (mainProps: any) => {
	const {
		mobile_number,
		country_code,
		on_success,
		on_failed,
		on_back,
		face_recognition,
		is_redirect_auth,
		from_bank,
		is_verified
	} = mainProps;
	const recipientId = GetQueryParams('id');
	const recipientStatus = GetQueryParams('status');
	const { get, isLoaded } = useNetwork();
	const [senderDetails, setSenderDetails] = useState({});
	
	const props = {
		mobileNumber: mobile_number ?? '8283847779',
		countryCode: country_code ?? '+91',
		onSuccess: on_success,
		onFailed: on_failed,
		onBack: on_back,
		face_recognition,
		is_redirect_auth,
		is_recipient: recipientId ? true : false,
		is_message_send: recipientId ? false : true,
		isrecipient_id: recipientId ?? '',
		recipient_status: recipientStatus ?? '',
		fromBank: from_bank === 'true',
		isVerified : is_verified === 'true'
		// fromBank: true,
		// user_address: address,
	};
	// eslint-disable-next-line no-console
	console.log('main props------->', mainProps);
	useEffect(() => {
		if (recipientId)
			get(`${API_URL.REQUEST_RECIPIENT + '/' + recipientId}`)
				.then((resp) => {
					setSenderDetails(resp?.data);
				})
				.catch((error) => error);
	}, []);

	return (
		<RecoilRoot>
			<BrowserRouter>
				<App
					{...props}
					senderDetails={senderDetails}
					checkRecipitentResp={isLoaded}
					// userAddress={userAddress}
				/>
				<ToastContainer
					position="top-right"
					autoClose={2000}
					hideProgressBar={false}
					closeOnClick
					pauseOnHover
					transition={Bounce}
					theme="dark"
					limit={1}
				/>
			</BrowserRouter>
		</RecoilRoot>
	);
};

Main.propTypes = {
	mobile_number: PropTypes.string,
	country_code: PropTypes.string,
	on_success: PropTypes.any,
	on_failed: PropTypes.any,
	on_back: PropTypes.any,
	is_recipient: PropTypes.string,
	is_message_send: PropTypes.string,
	from_bank: PropTypes.string,
	is_verified : PropTypes.any
};


// var authScript = document.createElement('script')
// authScript.src = 'https://biometrics.stage.satschel.com/script.js'
// document.head.append(authScript)