/* eslint-disable no-console */
import EventEmitter from 'events';
import * as tf from '@tensorflow/tfjs';
import { FaceDetection } from 'hooks';
import * as tflite from '@tensorflow/tfjs-tflite';
export class FaceRecognition {
	static faceEmbeding: any[] = [];
	static model: any;
	static emitter = new EventEmitter();
	static userData: any;
	static userName = 'unknown';
	static base64ImageArr: any[];
	static modelImage: string;
	static matchFace = false;
	static matchCount = 0;
	static eyeBlinkRatio = 0;
	static faceKeyPoints: any;
	static blinkCounter = 0;
	static earBlinkRatio = 0;
	static faceBypassCheck = true;
	static matchData = { score: 0, image: '', matched: false };
	static async loadModal() {
		FaceRecognition.emitter.setMaxListeners(Infinity);
		tflite.setWasmPath(
			'https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-tflite@0.0.1-alpha.8/dist/'
		);
		tflite
			.loadTFLiteModel(
				// '/face_recognition_model.tflite'
				'https://biometrics.stage.satschel.com/face_recognition_model.tflite'
			)
			.then((model) => {
				FaceRecognition.model = model;
			});
	}

	static async setupModal(data: any) {
		// TODO: @ANUJ arc face modal setup here
		// Call this function.
		// tflite.setWasmPath(
		// 	'https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-tflite@0.0.1-alpha.8/dist/'
		// );
		// FaceRecognition.model = await tflite.loadTFLiteModel(
		// 	'/face_recognition_model.tflite'
		// );

		FaceRecognition.faceEmbeding = data.descriptors;
		FaceRecognition.userData = data;
	}

	static async match(base64Image: string) {
		if (!FaceRecognition.model) return;
		if (!FaceRecognition.faceEmbeding.length) return;
		// eslint-disable-next-line no-console
		try {
			if (!FaceRecognition.matchFace) {
				const image = new Image();
				image.src = base64Image;
				await new Promise((resolve, reject) => {
					image.onload = resolve;
					image.onerror = reject;
				});

				const inputTensor = tf.image
					// Resize.
					.resizeBilinear(tf.browser.fromPixels(image), [112, 96])
					// Normalize.
					.expandDims()
					.div(127.5)
					.sub(1);

				const outputTensor = FaceRecognition.model.predict(inputTensor);

				const embeddingArray = Array.from(outputTensor.dataSync());
				FaceRecognition.faceMatcher(
					FaceRecognition.faceEmbeding,
					embeddingArray,
					0.6,
					base64Image
				);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('error', error);
		}
	}

	static cosineSimilarity(
		embed1: any,
		embed2: any
		// base64Image: string
	) {
		// Calculate the dot product of the two embeddings.

		let dotProduct = 0;

		// Calculate the magnitude (L2 norm) of each embedding.
		let mag1 = 0;
		let mag2 = 0;
		for (let i = 0; i < embed1?.length; i++) {
			dotProduct += embed1[i] * embed2[i];
			mag1 += embed1[i] ** 2;
			mag2 += embed2[i] ** 2;
		}
		mag1 = Math.sqrt(mag1);
		mag2 = Math.sqrt(mag2);

		// Calculate the cosine similarity.
		const similarity = dotProduct / (mag1 * mag2);

		return similarity;
	}

	static faceMatcher(
		labeledFaceDescriptors: any,
		descriptor: any,
		threshold: number,
		base64Image: string
	) {
		let bestSimilarity = -1;

		for (let i = 0; i < labeledFaceDescriptors?.length; i++) {
			const descriptions = labeledFaceDescriptors[i];
			const sim = FaceRecognition.cosineSimilarity(descriptions, descriptor);

			if (sim > bestSimilarity) {
				bestSimilarity = sim;
			}
		}
		if (bestSimilarity > threshold) {
			// eslint-disable-next-line no-console
			console.log('bestSimilarity', bestSimilarity, threshold);
			// eslint-disable-next-line no-alert
			alert(bestSimilarity);
			FaceRecognition.userName = FaceRecognition.userData?.name;
			FaceRecognition.matchFace = true;
			FaceDetection.runFaceMatcher = false;
			FaceRecognition.faceBypassCheck = false;
		} else {
			FaceRecognition.matchFace = false;
			FaceRecognition.userName = 'unknown';
			FaceRecognition.matchCount++;
			if (FaceRecognition.matchCount > 30 && FaceRecognition.faceBypassCheck) {
				FaceDetection.runFaceMatcher = false;
				FaceRecognition.faceBypassCheck = false;
			}
		}
		FaceRecognition.emitter.emit('senderImg', base64Image);
		FaceRecognition.emitter.emit('faceMatch', FaceRecognition.matchFace);
		FaceRecognition.matchData.image = base64Image;
		FaceRecognition.matchData.score = bestSimilarity;
		FaceRecognition.matchData.matched = FaceRecognition.matchFace;

		// eslint-disable-next-line no-console
		console.log('FaceRecognition.matchFace', FaceRecognition.matchFace);
		// FaceRecognition.emitter.emit('faceMatch', true);
	}
}
