/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-console */
import { Button, Loader } from '@storybook';
import { API_URL } from 'constant';
import { FaceDetection, useLocation, useNetwork } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	FacialPropsState,
	recipitentDetailsResponseState,
	senderDetailsState,
	SignInActiveStepState,
	userLocationState,
} from 'states';
import linkExpireImg from './assests/expired-link.svg';
import loadingGif from './assests/loading.gif';
import './recipient-approval.css';

export const RecipientApprovalScreen = () => {
	const setSignInActiveStep = useSetRecoilState(SignInActiveStepState);
	const senderDetails = useRecoilValue(senderDetailsState);
	const facialPropsState = useRecoilValue(FacialPropsState);
	const recipitentResp = useRecoilValue(recipitentDetailsResponseState);
	const [reject, setReject] = useState(false);
	const { patch } = useNetwork();
	const { locationInfo, fetchLocation } = useLocation();
	const setAddress = useSetRecoilState(userLocationState);
	const [ faceLoader, setFaceLaoder]= useState(true)

	useEffect(() => {
		if (!Object.keys(locationInfo ?? {}).length) {
			fetchLocation();
		}
		setAddress(locationInfo);
	}, [locationInfo]);

	const handleRejectTransctions = useCallback(() => {
		const payload = { status: 'rejected' };
		patch(
			API_URL.REQUEST_RECIPIENT + '/' + facialPropsState.isrecipient_id,
			payload
		)
			.then((resp) => {
				if (resp) {
					setReject(true);
				}
			})
			.catch((error) => error);
	}, []);

	useEffect(() => {
		const ClearFaceDetetion = setInterval(()=>{
			console.log('ClearFaceDetetion',FaceDetection.detector, !FaceDetection.detector ,!!FaceDetection.detector)
			if(!!FaceDetection.detector){
				setFaceLaoder(false)
				console.log(FaceDetection.detector)
				clearInterval(ClearFaceDetetion)
			}

		},400)
	}, []);

	if (!recipitentResp) {
		return (
			<div className="spinner-box">
				<img src={loadingGif} alt="loading" />
			</div>
		);
	}


	
	return (
		<div className="wrapper">
			{reject || senderDetails?.status !== 'pending' ? (
				<>
					<div className="waiting-box">
						<img src={linkExpireImg} alt="expired link" />
						<p className="expire-label">
							{senderDetails?.status !== 'pending'
								? 'Link is expired'
								: 'Transaction cancelled'}
						</p>
					</div>
				</>
			) : (
				<div className="approve-box">
					<div>
						<div className="sender-Img">
							{senderDetails.senderImage ? (
								<img src={senderDetails.senderImage} alt="sender-image" />
							) : (
								<div className="user-icon">
									<i
										className="ri-user-fill"
										style={{
											fontSize: '40px',
											color: '#9EA3B3',
										}}
									/>
								</div>
							)}
						</div>
						<p className="sender">
							{senderDetails?.senderName} sending{' '}
							{'$' + senderDetails.amount ?? 0} money to you
						</p>

						<div className="amount-wrapper">
							<div className="sender-amount">${senderDetails.amount ?? 0}</div>
							<div>amount </div>
							<div className="dollor-icon">
								<i className="ri-money-dollar-circle-line"></i>
							</div>
						</div>
					</div>
					<div className="amount-button-wrapper">
						<Button
							type={'button__filled--primary button__large button__block space'}
							label={faceLoader ? <Loader className="loader-white" dimension={14} /> : 'Receive'}
							handleClick={() => {
								setSignInActiveStep('face-recognize');
							}}
							disabled={faceLoader}
						/>

						<Button
							type={'button__filled--danger button__large button__block space'}
							handleClick={() => handleRejectTransctions()}
							label="Reject"
						/>
					</div>
				</div>
			)}
		</div>
	);
};
