import { Host_URL } from 'hooks';
import succussGif from '../../../face-auth/asests/success.gif';

import "./success-screen.scss";

export const SuccessScreen = () => {
	return (
		<div className="success-screen-wrapper">
			<div className="success-screen-icon">
					<img className="succuss-gif" src={Host_URL+succussGif} alt="succuss gif" />
					{/* <i className="ri-close-circle-fill cancel-icon"/> */}

            </div>
			<div className="success-screen-message">
				Please wait patiently while we redirect you to the desired page.
                {/* We apologize for the inconvenience. It seems that something went wrong. */}
			</div>
		</div>
	);
};
